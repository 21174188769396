



















































import { ComparacaoEstoque, EstoqueGenerico } from '@/models'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { CancelTokenSource } from 'axios'
import Vue from 'vue'
import Component from 'vue-class-component'
import { DataOptions } from 'vuetify'

@Component
export default class DialogoDeComparacaoDeEstoque extends Vue {
	mostra = false
	carregando = false
	listagemFormatada!: ComparacaoEstoque[]
	
	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 5,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	totalRegistros = -1

	cancelToken: CancelTokenSource | null = null

	headers = [
		{ text: 'Foto', value: 'urlImagem' },
		{ text: 'Nome', value: 'nome' },
		{ text: 'SKU REF.', value: 'skuProdutoPai' },
		{ text: 'SKU', value: 'sku' },
		{ text: 'Cor', value: 'cor' },
		{ text: 'Tamanho', value: 'tamanho' },		
		{ text: 'Coleção', value: 'colecao', align: 'center', sortable: false },
		{ text: 'Quantidade Lido', value: 'qtdeArquivo', align: 'center' },
		{ text: 'Disponível em Estoque', value: 'qtdeDisponivel', align: 'center' },
		{ text: 'Diferença', value: 'diferenca', align: 'center' },
	]

	async mostrar(listagemA: EstoqueGenerico[], listagemB: EstoqueGenerico[], isParcial: boolean) {
		this.mostra = true
		const listagemCompletaDeSku: string[] = []

		if (isParcial) {
			listagemB.forEach(item => {
				if (!listagemCompletaDeSku.includes(item.sku) && item.sku) {
					listagemCompletaDeSku.push(item.sku)
				}
			})
		} else {
			listagemA.forEach(item => {
				if (!listagemCompletaDeSku.includes(item.sku) && item.sku) {
					listagemCompletaDeSku.push(item.sku)
				}
			})

			listagemB.forEach(item => {
				if (!listagemCompletaDeSku.includes(item.sku) && item.sku) {
					listagemCompletaDeSku.push(item.sku)
				}
			})
		}		

		this.listagemFormatada = listagemCompletaDeSku.map(item => {
			const estoqueA = listagemA.filter(itemA => itemA.sku === item)
			const estoqueB = listagemB.filter(itemB => itemB.sku === item)			
			
			if (estoqueA.length > 0 && estoqueB.length > 0) {
				return {
					nome: estoqueA[0].nome, 
					sku: estoqueA[0].sku,
					qtdeArquivo: Number(estoqueB[0].quantidade),
					qtdeDisponivel: estoqueA[0].quantidade,
					diferenca: estoqueB[0].quantidade as number - estoqueA[0].quantidade as number,
					skuProdutoPai: estoqueA[0].skuProdutoPai,
					urlImagem: estoqueA[0].urlImagem,
					cor: estoqueA[0].cor,
					tamanho: estoqueA[0].tamanho,
					colecao: estoqueA[0].colecao || '',
				} as ComparacaoEstoque
			} else if (estoqueA.length > 0 && estoqueB.length === 0) {
				return {
					nome: estoqueA[0].nome, 
					sku: estoqueA[0].sku,
					qtdeArquivo: 0,
					qtdeDisponivel: estoqueA[0].quantidade,
					diferenca: - Math.abs(estoqueA[0].quantidade),
					skuProdutoPai: estoqueA[0].skuProdutoPai,
					urlImagem: estoqueA[0].urlImagem,
					cor: estoqueA[0].cor,
					tamanho: estoqueA[0].tamanho,
					colecao: estoqueA[0].colecao || '',
				} as ComparacaoEstoque
			} else if (estoqueA.length === 0 && estoqueB.length > 0) {
				return {
					nome: 'Produto novo neste deposito', 
					sku: estoqueB[0].sku,
					qtdeArquivo: Number(estoqueB[0].quantidade),
					qtdeDisponivel: 0,
					diferenca: Math.abs(estoqueB[0].quantidade as number),
					skuProdutoPai: estoqueB[0].skuProdutoPai,
					urlImagem: estoqueB[0].urlImagem,
					cor: estoqueB[0].cor,
					tamanho: estoqueB[0].tamanho,
					colecao: estoqueB[0].colecao || '',
				} as ComparacaoEstoque
			} else {
				return {
					nome: '', 
					sku: '',
					qtdeArquivo: 0,
					qtdeDisponivel: 0,
					diferenca: 0,
					skuProdutoPai: '',
					urlImagem: '',
					cor: '',
					tamanho: '',
					colecao: '',
				} as ComparacaoEstoque
			}
		})
	}

	exportarCsv() {
		try{
			const header = "nome,skuRef,sku,cor,tamanho,colecao,qtdeLido,qtdeDisponivel,diferenca\n";
			const csvContent = this.listagemFormatada
				.map(item => `${item.nome},${item.skuProdutoPai},${item.sku},${item.cor},${item.tamanho},${item.colecao},${item.qtdeArquivo},${item.qtdeDisponivel},${item.diferenca}`)
				.join("\n");
			const csvData = header + csvContent;

			const blob = new Blob([csvData], { type: "text/csv" });
			const url = window.URL.createObjectURL(blob)
			const link = document.createElement('a')

			const dataAtual = new Date()
			
			const dataFormatada = dataAtual.toLocaleDateString().replaceAll('-', '_')
			const horaFormatada = dataAtual.toLocaleTimeString().replaceAll('-', '_')

			link.href = url
			link.setAttribute('download', `Comparacao_de_estoques_${dataFormatada + '_' + horaFormatada}.csv`)

			document.body.appendChild(link)

			link.click()

			document.body.removeChild(link)
		}catch(e){
			AlertModule.setError(e)
		}
	}
}
