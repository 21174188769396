var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1230px"},model:{value:(_vm.mostra),callback:function ($$v) {_vm.mostra=$$v},expression:"mostra"}},[_c('v-card',{attrs:{"width":"auto","height":"auto"}},[_c('v-card-title',{staticClass:"primary white--text pt-2 pl-3 justify-space-between"},[_c('div',[_vm._v("Movimentação de Estoque")])]),_c('v-card-text',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.buscarMovimentacoes.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{ref:"campoDeBusca",attrs:{"label":"Consulta","prepend-icon":"mdi-magnify"},model:{value:(_vm.filtro.skuOuEan),callback:function ($$v) {_vm.$set(_vm.filtro, "skuOuEan", $$v)},expression:"filtro.skuOuEan"}})],1),_c('v-col',{attrs:{"cols":"8","sm":""}},[_c('RangeDatePicker',{attrs:{"inicial":{
								label: 'Data Inicial',
								clearable: true,
							},"final":{
								label: 'Data Final',
								clearable: true,
							}},model:{value:(_vm.filtro.datas),callback:function ($$v) {_vm.$set(_vm.filtro, "datas", $$v)},expression:"filtro.datas"}})],1),_c('v-col',{staticClass:"d-flex align-center text-center",attrs:{"cols":"2","sm":"auto"}},[_c('v-btn',{attrs:{"color":"primary","width":"110","type":"submit"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-magnify ")]),_vm._v(" Buscar ")],1)],1),_c('v-col',{staticClass:"d-flex align-center text-center",attrs:{"cols":"3","sm":"auto"}},[(_vm.tab === 0)?_c('v-btn',{attrs:{"color":"primary","loading":_vm.exportandoParaCsv},on:{"click":_vm.exportarCsv}},[_vm._v(" Exportar CSV ")]):_vm._e()],1)],1)],1),_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:tab},[_vm._v(_vm._s(tab))])}),_c('v-tab-item',{attrs:{"eager":""}},[_c('v-container',[_c('DataTableDeCrud',{attrs:{"search":_vm.filtro.skuOuEan,"items":_vm.itensFormatadosMovimentacaoAnalitica,"server-items-length":_vm.totalDeElementosAnaliticos,"headers":_vm.headerMovimentacao,"options":_vm.paginacaoAnalitica,"footer-props":{ itemsPerPageOptions: [5, 10, 15, 30, 50] },"hideActions":"","hideCreate":"","loading":_vm.buscandoAnalitica},on:{"update:options":function($event){_vm.paginacaoAnalitica=$event}},scopedSlots:_vm._u([{key:"item.tipo",fn:function(ref){
							var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
return [_c('div',_vm._g({staticClass:"mensagem-de-erro"},on),[_vm._v(" "+_vm._s(item.tipo)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.tipo))])])]}},{key:"item.infoAdicional",fn:function(ref){
							var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(" "+_vm._s(item.infoAdicional)+" "),(item.infoAdicional)?_c('v-icon',_vm._g({staticStyle:{"cursor":"pointer"},attrs:{"small":""},on:{"click":function($event){return _vm.irPara(item.infoAdicional)}}},on),[_vm._v(" mdi-link-variant ")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Ir para a venda")])])]}},{key:"item.modalidade",fn:function(ref){
							var item = ref.item;
return [_c('v-chip',{staticClass:"custom-chip",style:({ color: _vm.getTextColor(item.modalidade),
										backgroundColor: _vm.$vuetify.theme.dark ? '#424242' : '#757575'
									}),attrs:{"small":""}},[_vm._v(" "+_vm._s(item.modalidade ? item.modalidade : 'Sem Modalidade')+" ")])]}}],null,true)})],1)],1),_c('v-tab-item',{attrs:{"eager":""}},[_c('v-container',[_c('v-data-table',{attrs:{"search":_vm.filtro.skuOuEan,"items":_vm.itensFormatadosMovimetacaoSintetica,"server-items-length":_vm.totalDeElementosSinteticos,"headers":_vm.headerMovimentacaoSintetica,"options":_vm.paginacaoSintetica,"footer-props":{ itemsPerPageOptions: [5, 10, 15, 30, 50] },"hideActions":"","hideCreate":"","loading":_vm.buscandoSintetica},on:{"update:options":function($event){_vm.paginacaoSintetica=$event}},scopedSlots:_vm._u([{key:"item.tipo",fn:function(ref){
									var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
									var on = ref.on;
return [_c('div',_vm._g({staticClass:"mensagem-de-erro"},on),[_vm._v(" "+_vm._s(item.tipo)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.tipo))])])]}},{key:"item.info_adicional",fn:function(ref){
									var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
									var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(" "+_vm._s(item.info_adicional)+" "),(item.info_adicional)?_c('v-icon',_vm._g({staticStyle:{"cursor":"pointer"},attrs:{"small":""},on:{"click":function($event){return _vm.irPara(item.info_adicional)}}},on),[_vm._v(" mdi-link-variant ")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Ir para a venda")])])]}},{key:"item.modalidade",fn:function(ref){
									var item = ref.item;
return [_c('v-chip',{staticClass:"custom-chip",style:({ color: _vm.getTextColor(item.modalidade),
										backgroundColor: _vm.$vuetify.theme.dark ? '#424242' : '#757575'
									}),attrs:{"small":""}},[_vm._v(" "+_vm._s(item.modalidade ? item.modalidade : 'Sem Modalidade')+" ")])]}}],null,true)})],1)],1)],2),_c('v-btn',{staticStyle:{"position":"absolute","right":"10px","top":"10px"},attrs:{"icon":""}},[_c('v-icon',{attrs:{"color":"white"},on:{"click":function () { return _vm.esconder(); }}},[_vm._v(" mdi-close ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }